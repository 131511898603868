/* eslint-disable no-magic-numbers */
import isNil from 'lodash/isNil'
import validator from 'validator'
import * as yup from 'yup'
import { ObjectShape } from 'yup/lib/object'

import { CommonRegExp } from '@alteos/ui'

import { checkSubDomainAvailability } from '../../../api/partner.service'
import { CountryCodes, countryBasedConstants, errorLabels } from '../../../dictionaries'
import { IProductSelectionOptionsEntity } from '../../../types/registerForm'
import { IProductConfigExtension } from '../../../utils/trim'
import {
  ALL_BRILLEN_PRODUCT_SELECTED,
  BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED,
  BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED,
  NON_BRILLEN_PRODUCT_SELECTED,
} from '../constants'
import { IGetOnSubmitFormFnValues, IProductSelection } from '../registerForm'
import { getProductSelectionContracts } from './registerForm'

const maxSymbolsAmount = 100
const plsSymbolsAmount = (country: CountryCodes) => (country === CountryCodes.DE ? 5 : 4)

yup.addMethod(yup.string, 'isIBAN', function (message: string) {
  return this.test('isIBAN', message || errorLabels.billingIban, function (value) {
    if (!value) return true
    return validator.isIBAN(value)
  })
})

export const generateBillingIbanValidation = (
  { productSelection = {}, productConfigExtension, country } = {} as {
    productSelection: IProductSelection
    productConfigExtension: IProductConfigExtension
    country: CountryCodes
  }
): yup.StringSchema => {
  const constants = countryBasedConstants[country]
  // filter out products that are not selected
  const filteredProductSelection = Object.keys(productSelection).filter(
    (key) => productSelection[key] === true
  )

  const isRequiredIBAN =
    filteredProductSelection.length === 1 &&
    productConfigExtension?.[filteredProductSelection[0]]?.values?.commissionRate === 0
      ? 'notRequired'
      : 'required'

  return yup
    .string()
    .trim()
    .matches(constants.iban.regex, {
      excludeEmptyString: true,
      message: errorLabels.billingIban,
    })
    .isIBAN(errorLabels.billingIban)
    [isRequiredIBAN](errorLabels.required)
}

export const generateBillingEmailValidation = (): yup.StringSchema => {
  return yup
    .string()
    .trim()
    .required(errorLabels.required)
    .matches(CommonRegExp.email, errorLabels.incorrectEmail)
}

export const generatePhoneNumberValidation = (country: CountryCodes): yup.StringSchema => {
  const constants = countryBasedConstants[country]
  return yup
    .string()
    .trim()
    .matches(constants.phone.regex, {
      excludeEmptyString: true,
      message: errorLabels.incorrectPhone,
    })
    .min(constants.phone.minLength, errorLabels.tooShort)
    .max(constants.phone.maxLength, errorLabels.tooLong)
}

export const validationSchema = (
  country: CountryCodes,
  productConfigExtensionValidator: (productSelection: IProductSelection) => ObjectShape,
  options = {} as Partial<IGetOnSubmitFormFnValues>,
  focusedField?: string
): yup.AnyObjectSchema => {
  const constants = countryBasedConstants[country]
  return yup.object().shape({
    firmName: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .required(errorLabels.required),
    legalRepFirstName: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .required(errorLabels.required),
    legalRepLastName: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .required(errorLabels.required),
    legalEntity: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .required(errorLabels.required),
    salesTaxIdNumber: yup
      .string()
      .trim()
      .max(14, errorLabels.tooLong)
      .matches(constants.taxId.regex, {
        excludeEmptyString: true,
        message: errorLabels.incorrectTaxId,
      })
      .required(errorLabels.required),
    issuingAuthority: yup.string().trim().max(maxSymbolsAmount, errorLabels.tooLong).notRequired(),
    firmRegistrationNumber: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .notRequired(),
    billingEmail: generateBillingEmailValidation(),
    billingIban: generateBillingIbanValidation({
      productSelection: options?.productSelection,
      productConfigExtension: options?.productConfigExtension,
      country,
    }),
    firmAddressCity: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .required(errorLabels.required),
    firmAddressHouse: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .required(errorLabels.required),
    firmAddressPlz: yup
      .string()
      .trim()
      .matches(constants.plz.regex, {
        excludeEmptyString: true,
        message: errorLabels.incorrectValue,
      })
      .max(plsSymbolsAmount(country), errorLabels.tooLong)
      .min(plsSymbolsAmount(country), errorLabels.tooShort)
      .required(errorLabels.required),
    firmAddressStreet: yup.string().trim().required(errorLabels.required),

    firmTelephoneNumber: generatePhoneNumberValidation(country),

    firmEmailId: yup
      .string()
      .trim()
      .required(errorLabels.required)
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .matches(CommonRegExp.email, errorLabels.incorrectEmail),
    firmEmailIdRepeat: yup
      .string()
      .trim()
      .required(errorLabels.required)
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .matches(CommonRegExp.email, errorLabels.incorrectEmail)
      .oneOf([yup.ref('firmEmailId'), null], errorLabels.emailNotMatch),

    productSelection: yup.object().test('is-product-choosen', errorLabels.required, (value) => {
      if (!isNil(value) && (Object.values(value) as boolean[]).includes(true)) {
        return true
      }
      return false
    }),

    productConfigExtension: yup.object(productConfigExtensionValidator(options.productSelection)),

    desiredURL: yup
      .string()
      .required(errorLabels.required)
      .trim()
      .max(40, errorLabels.tooLong)
      .test(
        'is-url-available',
        errorLabels.desiredURLexists,
        async function getAvailableData(value) {
          const { createError, path } = this
          const constants = countryBasedConstants[country]
          if (typeof value === 'undefined' || !constants.subdomain.regex.test(value)) {
            return createError({ path, message: errorLabels.desiredURLwrongFormat })
          }
          // only run api call if field is focused
          if (focusedField === 'desiredURL') {
            try {
              const response = await checkSubDomainAvailability(value)
              return response.data.available === true
            } catch (error) {
              return false
            }
          }
          return true
        }
      ),
    source: yup
      .string()
      .trim()
      .max(maxSymbolsAmount, errorLabels.tooLong)
      .required(errorLabels.required),
    sourceReferral: yup.string().trim().max(maxSymbolsAmount, errorLabels.tooLong),
    disclosureLegalDocuments: yup.boolean().oneOf([true], errorLabels.required),
    ...productSelectionContractValidator(options.productSelection),
  })
}

const productSelectionContractValidator = (productsSelection: IProductSelection = {}) => {
  const productSelectionContracts = getProductSelectionContracts(productsSelection)

  const getRule = (bool: boolean) =>
    bool ? yup.boolean().oneOf([true], errorLabels.required) : yup.boolean().notRequired()

  return {
    disclosureContract: getRule(productSelectionContracts[NON_BRILLEN_PRODUCT_SELECTED]),
    disclosureWeitsichtPlus: getRule(
      productSelectionContracts[BRILLENSCHUTZ_WEITSICHT_PLUS_SELECTED]
    ),
    disclosurePremiumschutz: getRule(
      productSelectionContracts[BRILLENSCHUTZ_PREMIUMSCHUTZ_SELECTED]
    ),
    disclosurePremiumschutzWeitsichtPlus: getRule(
      productSelectionContracts[ALL_BRILLEN_PRODUCT_SELECTED]
    ),
  }
}

export const productConfigExtensionValidator =
  (productSelectionOptions: IProductSelectionOptionsEntity[]) =>
  (productsSelection: IProductSelection): ObjectShape =>
    productSelectionOptions.reduce((acc, item) => {
      if (item.hasConfigOption === true && productsSelection?.[item.key] === true) {
        return {
          ...acc,
          [item.key]: yup.object({
            values: yup.object({
              ...item.configOption.reduce((confAcc, confCurr) => {
                if (confCurr.required === true) {
                  return {
                    ...confAcc,
                    [confCurr.id]: yup.mixed().required(errorLabels.required),
                  }
                }
                return acc
              }, {}),
            }),
          }),
        }
      }
      return acc
    }, {})
